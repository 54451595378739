<template>
  <div class="pr">
    <div class="login-top flex-jb-al">
      <div class="flex-al">
        <img  class="mr-10" src="../assets/images/logo.png" alt>
        <!-- <span class="f24 color-f">智易宝信息管理系统</span> -->
      </div>
      <div class="f14 color-f">
        <span class="mr-30 cur" @click="loginShow=true">登录</span>
      </div>
    </div>
    <div class="login-bg"></div>
    <div style="font-size:36px;margin-top:96px" class="color-0 t-c">智易宝为客户提供强大的服务保障</div>
    <div class="login-bd m-0 flex-j-b" style="margin-top:106px">
      <div class="login-bd-item">
        <div class="t-c" style="margin-top:42px;">
          <img src="../assets/images/p1.png" alt>
        </div>
        <p class="t-c mt-30 f18 color-0 bd">线上签合同</p>
        <p class="mt-30 p40 f14">电子合同签署方便快捷，告别费时费力的异地签署的难处，电子合同采用数字签名、数据保护技术防止篡改。</p>
      </div>
      <div class="login-bd-item">
        <div class="t-c" style="margin-top:42px;">
          <img src="../assets/images/p2.png" alt>
        </div>
        <p class="t-c mt-30 f18 color-0 bd">案件进度实时监控</p>
        <p class="mt-30 p40 f14">合同从签署完毕后，合同的审核进度，案件的公司内部流转阶段实时可查，为客户提供安心保障。</p>
      </div>
      <div class="login-bd-item">
        <div class="t-c" style="margin-top:42px;">
          <img src="../assets/images/p3.png" alt>
        </div>
        <p class="t-c mt-30 f18 color-0 bd">提案实时沟通</p>
        <p class="mt-30 p40 f14">客户可查代理人填写的提案，可对提案提出修改意见。</p>
      </div>
      <div class="login-bd-item mt-30">
        <div class="t-c" style="margin-top:42px;">
          <img src="../assets/images/p4.png" alt>
        </div>
        <p class="t-c mt-30 f18 color-0 bd">案件一跟到底</p>
        <p class="mt-30 p40 f14">智易宝为客户提供从合同签署到办理结束的所以过程，专利还可查看专利局的审核情况。</p>
      </div>
      <div class="login-bd-item mt-30">
        <div class="t-c" style="margin-top:42px;">
          <img src="../assets/images/p5.png" alt>
        </div>
        <p class="t-c mt-30 f18 color-0 bd">专人贴心服务</p>
        <p class="mt-30 p40 f14">所有合同专人对接，一对一贴心服务。</p>
      </div>
      <div class="login-bd-item mt-30">
        <div class="t-c" style="margin-top:42px;">
          <img src="../assets/images/p6.png" alt>
        </div>
        <p class="t-c mt-30 f18 color-0 bd">受理进度实时更新</p>
        <p class="mt-30 p40 f14">客户可查看代理人撰写的提案，可对提案提出修改意见</p>
      </div>
    </div>
    <div class="login-foot"></div>
    <div
      style="height: 80px;background: #0b1e52;line-height:80px"
      class="t-c f14 color-f"
    >服务电话：400-862-1993</div>
    <div class="login" v-if='loginShow' @click.self="loginShow=false">
      <div class="login-tip pa">
        <div class="t-c mt-30 f18 color-0 bd">智易宝信息管理系统</div>
        <div class="name mt-30 t-c">
          <span class="f14 bd color-0 mr-10">手机号</span>
          <input type="text" v-model.trim="phone" >
        </div>
        <div class="v-code mt-30 flex">
          <span class="f14 bd color-0 mr-10">验证码</span>
          <div>
            <input type="text" v-model.number.trim="code" v-on:keyup.enter="login">
            <span v-if="isShow" class="f12 obtain" @click="obtainCode">获取验证码</span>
             <span v-else class="f12">{{str}}s后可重新获取</span>
          </div>
        </div>
        <div class="t-c login-btn color-f m-0" style="margin-top:60px" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created(){
     this.login1()
  },
  data(){
    return{
      loginShow:false,
      phone:'',
      isShow:true,
      str:60,
      code:'',
    }
  },
 methods:{
  obtainCode(){
    if(this.phone==''){
       this.$message({type: 'error', message: '请您输入手机号',duration:2000})
       return
    }
    var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if(!myreg.test(this.phone)){
       this.$message({type: 'error', message: '手机号格式错误',duration:2000})
       return
    }
    this.isShow=false
    let time=setInterval(()=>{
      if(this.str==0){
        this.isShow=true
        this.str=60
        clearInterval(time)
      }else{
        this.str-=1
      }
    },1000)
    this.$request.post('/client/login/code',{phone:this.phone}).then(res=>{
    })
  },
  login1(){
      let token=window.localStorage.token
    if(token){
      this.$router.replace('/index')
    }
  },
  login(){
   
   if(this.phone==''){
       this.$message({type: 'error', message: '请您输入手机号',duration:2000})
       return
    }
    var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if(!myreg.test(this.phone)){
       this.$message({type: 'error', message: '手机号格式错误',duration:2000})
       return
    }
    if(this.code==''){
       this.$message({type: 'error', message: '请您输入验证码',duration:2000})
       return
    }
    if(!/^[0-9]*$/.test(this.code)){
            this.$message({type: 'error', message: '验证码格式错误',duration:2000})
            return
    }
    this.$request.post('/client/login/login',{phone:this.phone,code:this.code}).then(res=>{
        if(res.data.code==200){
          window.localStorage.setItem('token',res.data.data)
        this.$router.replace('/home')
        }
      }
    )
 }
 },
 

}
</script>

<style scoped>
.login-top {
  height: 80px;
  background: #0b1e52;
  padding: 0 110px 0 200px;
  box-sizing: border-box;
  margin: 0  auto;
}
.login-bg {
  height: 420px;
  background-image: url("../assets/images/loginbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1920px 100%;
  background-color: #02025d
}
.login-bd {
  width: 1180px;

  flex-wrap: wrap;
}
.login-bd-item {
  width: 380px;
  height: 460px;
  border: 1px solid transparent;
}
.p40 {
  padding: 0 40px;
}
.login-foot {
  height: 420px;
  background-image: url("../assets/images/footbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.cur {
  cursor: pointer;
}
.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.login-tip {
  width: 400px;
  height: 324px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -162px;
  border-radius: 6px;
}
.v-code {
  justify-content: center;
  align-items: center;
}
.v-code div input {
  width: 140px;
  height: 32px;
  border: none;
  border-bottom: 1px solid;
}
.v-code div input:focus {
  outline: none;
  border-bottom-color: #2962ff;
}
.v-code div {
  width: 240px;
  height: 33px;
}
.v-code div .obtain {
  display: inline-block;
  width: 90px;
  background-color: #2962ff;
  height: 100%;
  color: #fff;
  margin-left: 10px;
  line-height: 33px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}
.name input {
  height: 32px;
  width: 240px;
  border: none;
  border-bottom: 1px solid;
}
.name input:focus {
  outline: none;
  border-bottom-color: #2962ff;
}
.login-btn {
  width: 292px;
  height: 40px;
  background: #2962ff;
  border-radius: 4px;
  line-height: 40px;
  cursor: pointer;
}
</style>
